<template>
	<div v-loading="pageloading">
		<el-card shadow="never">
			<div class="theFlex">
				<div class="search-box">
					<div style="font-size:14px;line-height:37px;font-weight: bold;color: #606266;">关键字:</div>
					<el-input v-model="keywords" placeholder="标签组名称、标签名称" style="width:250px;margin:0px 10px 5px 10px;">
					</el-input>
					<div>
						<el-button type="primary" style="margin:0px 10px 5px 10px;width:90px" @click="query">查询
						</el-button>
					</div>
					<div>
						<el-button type="primary" style="margin:0px 10px 5px 10px" @click="tagListAdd">添加标签组</el-button>
					</div>
				</div>
			</div>
		</el-card>
		<div style="margin-top:10px" class="flexRow">
			<el-card shadow="never" style="width:450px;height:730px;float:left;" v-loading="leftLoading">
				<div v-if="leftGroupList.length<=0" style="font-size:14px;text-align:center;color:rgb(150,150,150)">暂无数据
				</div>
				<div v-else style="width:100%;height:690px;overflow:hidden;overflow-y:auto;padding:0ox 0px 0px 15px">
					<div style="width:100%;padding:0px 3px" class="tag-item"
						:class="{tagNameBgc:changeLeftTag==i||mouseoverNum==i}" @mouseover="mouseover(i)"
						@mouseout="mouseout" v-for="(v,i) in leftGroupList" :key="i">
						<div class="tag-item-left" @click="queryList(v,i)">
							<div class="name">
								<div class="fontHidden1" :class="{chooseTag:changeLeftTag==i}">{{v.Name}}</div>
							</div>
							<!-- <span v-html="'\u00a0'"></span> -->
							<div class="count">({{v.CRMTagDetailCount}})</div>
						</div>
						<div :class="{'tagNameBtn-Box':changeLeftTag!=i}" class="tag-item-right">
							<el-button type="text" style="padding:0px;margin:0px 5px;line-height:30px"
								@click="tagListEdit(v)">编辑</el-button>
							<el-button type="text" style="padding:0px;margin:0px 5px;line-height:30px"
								@click="tagAdd(v)">
								添加标签</el-button>
							<el-button type="text"
								style="color:rgb(245,108,108);padding:0px;margin:0px 5px;line-height:30px"
								@click="tagDetele(v)">删除</el-button>
						</div>
					</div>
				</div>
			</el-card>
			<el-card shadow="never"
				style="flex:1;width:100%;height:730px;overflow:hidden;overflow-y:auto;margin-left:10px">
				<div style="font-size:14px;color: #666;margin-bottom: 5px;" v-if="TagGroup.Name">{{TagGroup.Name}}：
				</div>
				<el-table :data="tablelist" v-loading="tableloading" style="margin-top:10px">
					<el-table-column prop="Name" label="标签名称" show-overflow-tooltip>
						<template slot-scope="scope">
							<div class="fontHidden1">
								{{scope.row.Name}}
							</div>
						</template>
					</el-table-column>
					<!-- <el-table-column prop="MemberCount" label="已打标签人数">
						<template slot-scope="scope">
							<div v-if="scope.row.MemberCount>0" style="color:#409EFF;cursor:pointer;"
								@click="tocuseomerSearch(scope.row)">
								{{scope.row.MemberCount}}
							</div>
							<div v-else style="#606266">
								{{scope.row.MemberCount}}
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="RuleCount" label="标签匹配规则">
						<template slot-scope="scope">
							<div v-if="scope.row.RuleCount>0" style="color:#409EFF;cursor:pointer;"
								@click="toautoAddTag(scope.row)">
								{{scope.row.RuleCount}}
							</div>
							<div v-else style="#606266">
								{{scope.row.RuleCount}}
							</div>
						</template>
					</el-table-column> -->
					<el-table-column prop="AddTime" label="创建时间"></el-table-column>
					<el-table-column prop="" label="操作">
						<template slot-scope="scope">
							<el-button type="text" @click="edittable(scope.row)" style="margin-right: 5px">编辑</el-button>
							<!-- <buttonPermissions datas="tooperating" style="margin:0px 5px">
								<el-button type="text" @click="operating(scope.row)">去运营</el-button>
							</buttonPermissions>
							<buttonPermissions datas="todistribution" style="margin:0px 5px">
								<el-button type="text" @click="distribution(scope.row)">去分配</el-button>
							</buttonPermissions> -->
							<el-button type="text" style="color:rgb(245,108,108);margin-left:5px" @click="deltable(scope.row)">删除
							</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div style="margin-top:5px;text-align:right" v-if="total">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10, 20, 30, 40,50]" :page-size="sizepage"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>
			</el-card>
		</div>
		<!-- 添加标签 -->
		<el-dialog title="添加标签" :visible.sync="addTagshow" v-loading="addTagLoading" width="500px"
			@closed="addTagClosed">
			<div style="max-height:500px;overflow-y:auto">
				<div style="display:flex;flex-direction:row">
					<div style="font-size:14px;margin:0px 0px 20px 20px;width:100px;text-align:left;font-weight:bold">
						标签组名称:</div>
					<div style="width:290px;margin-left:-20px">{{addruleForm.Name}}</div>
				</div>
				<el-form :model="addruleForm" :rules="addrules" ref="addTagForm" style="margin-top:10px">
					<el-form-item :prop="'CRMTagDetails.'+i+'.Name'" v-for="(v,i) in addruleForm.CRMTagDetails" :key="i"
						:label="i==0?'标签名称:':''" label-width="100px" :rules="addrules.Name">
						<el-input v-model.trim="v.Name" :maxlength="maxlength" placeholder="请输入标签名称" @input="limit30"
							style="width:300px"></el-input>
						<i v-show="addruleForm.CRMTagDetails.length > 1" class="el-icon-close"
							style="margin-left:15px;cursor:pointer;" @click="closeIconDelTag(i)"></i>
					</el-form-item>
				</el-form>
			</div>
			<el-button type="text" icon="el-icon-circle-plus-outline" style="margin-left:100px" @click="pushInTag">
				添加标签</el-button>
			<div style="text-align:right">
				<el-button @click="addTagshow = false">取消</el-button>
				<el-button type="primary" @click="saveAddTag('addTagForm')">保存</el-button>
			</div>
		</el-dialog>
		<!-- 编辑标签组 -->
		<el-dialog title="编辑标签组" :visible.sync="editTagListshow" v-loading="editTagListLoading" width="500px"
			@closed="editTagListClosed">
			<el-form :model="editruleForm" :rules="editrules" ref="editTagListForm">
				<el-form-item prop="Name" label="标签组名称:" label-width="100px">
					<el-input v-model.trim="editruleForm.Name" :maxlength="maxlength" @input="limit30"
						style="width:300px" placeholder="请输入标签组名称">
					</el-input>
				</el-form-item>
			</el-form>
			<div style="text-align:right">
				<el-button @click="editTagListshow = false">取消</el-button>
				<el-button type="primary" @click="saveEditTagList('editTagListForm')">保存</el-button>
			</div>
		</el-dialog>
		<!-- 添加标签组 -->
		<el-dialog title="添加标签组" :visible.sync="addTagListshow" v-loading="addTagListLoading" width="500px"
			@closed="addTagListClosed">
			<div style="max-height:500px;overflow-y:auto">
				<el-form :model="addListruleForm" :rules="addListrules" ref="addTagListForm">
					<el-form-item prop="Name" label="标签组名称:" label-width="100px">
						<el-input v-model.trim="addListruleForm.Name" placeholder="请输入标签组名称" :maxlength="maxlength"
							@input="limit30" style="width:300px"></el-input>
					</el-form-item>
					<el-form-item v-for="(v,i) in addListruleForm.CRMTagDetails" :key="i" :prop="'CRMTagDetails.'+i+'.Name'"
						:rules="addListrules.CRMTagDetails" :label="i==0?'标签名称:':''" label-width="100px">
						<el-input v-model.trim="v.Name" :maxlength="maxlength" @input="limit30" placeholder="请输入标签名称"
							style="width:300px"></el-input>
						<i v-show="addListruleForm.CRMTagDetails.length > 1" class="el-icon-close"
							style="margin-left:15px;cursor:pointer;" @click="closeIconDel(i)"></i>
					</el-form-item>
				</el-form>
			</div>
			<el-button type="text" icon="el-icon-circle-plus-outline" style="margin-left:100px" @click="pushTag">添加标签
			</el-button>
			<div style="text-align:right">
				<el-button @click="addTagListshow = false">取消</el-button>
				<el-button type="primary" @click="saveAddTagList('addTagListForm')">保存</el-button>
			</div>
		</el-dialog>

		<!-- 编辑表格内标签 -->
		<el-dialog title="编辑标签" :visible.sync="editTableshow" v-loading="editTableLoading" width="500px"
			@closed="editTableClosed">
			<el-form :model="editTableTag" :rules="editTableTagRules" ref="editTableForm">
				<el-form-item label="标签名称:" label-width="100px" prop="Name">
					<el-input v-model.trim="editTableTag.Name" :maxlength="maxlength" @input="limit30"
						style="width:300px" placeholder="请输入标签名称"></el-input>
				</el-form-item>
			</el-form>
			<div style="text-align:right">
				<el-button @click="editTableshow = false">取消</el-button>
				<el-button type="primary" @click="saveEditTableTag('editTableForm')">保存</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		groupList,
		groupedit,
		groupadd,
		groupdel,
		tagList,
		tagadd,
		tagdel,
		tagedit,
    crmTagGrouplist,
    crmTagGroupedit,
    crmTagGroupadd,
    crmTagGroupdel,
    crmTagDaillist,
    crmTagDetailadd,
    crmTagDetaildel,
    crmTagDetailedit,
	} from "@/api/TurnTomySelf"
	import config from '@/config/index'
	import buttonPermissions from '@/components/buttonPermissions';
	export default {
		components: {
			buttonPermissions
		},
		data() {
			var IsEmptyList = (rule, value, callback) => {
				if (value == '') {
					callback(new Error("请输入标签组名称"))
				} else {
					callback()
				}
			};
			var IsEmpty = (rule, value, callback) => {
				if (value == '') {
					callback(new Error("请输入标签名称"))
				} else {
					callback()
				}
			};
			var limitCharacter = (rule, value, callback) => {
				let len = 0
				for (let i = 0; i < value.length; i++) {
					var a = value.charAt(i);
					if (a.match(/[^\x00-\xff]/ig) != null) {
						//汉字占两个字符
						len += 2;
					} else {
						//其他占一个字符
						len += 1;
					}
				}
				if (len > 30) {
					callback(new Error('最长可输入30个字符'))
				} else {
					callback()
				}

			};

			return {
				exportUrl: config.EXPORT_URL,
				pageloading: false,
				keywords: '',
				// 	synchronous: {
				// 		loading: true,
				// 		tip: '同步中...'
				// },
				leftGroupList: [],
				leftLoading: false,
				tablelist: [],
				tableloading: false,
				currentPage: 1,
				sizepage: 20,
				total: null,
				addruleForm: { //添加小标签
					CRMTagGroupId: 0,
					Name: '',
					CRMTagDetailCount: 0,
					CRMTagDetails: [{
						Name: '',
					}]
				},
				addrules: {
					Name: [{
						required: true,
						trigger: 'blur',
						validator: IsEmpty
					}, {
						required: true,
						trigger: 'change',
						validator: limitCharacter
					}, ],
				},
				editruleForm: { //编辑标签组
					Name: '',
					CRMTagDetailCount: 0,
					CRMTagGroupId: 0,
				},
				editrules: {
					Name: [{
						required: true,
						trigger: 'blur',
						validator: IsEmptyList
					}, {
						required: true,
						trigger: 'change',
						validator: limitCharacter
					}, ],
				},
				addListruleForm: { //添加标签组
					Name: '',
					CRMTagDetails: [{
						Name: ''
					}, ],
				},
				addListrules: {
					Name: [{
						required: true,
						trigger: 'blur',
						validator: IsEmptyList
					}, {
						required: true,
						trigger: 'change',
						validator: limitCharacter
					}, ],
					CRMTagDetails: [{
						required: true,
						trigger: 'blur',
						validator: IsEmpty
					}, {
						required: true,
						trigger: 'change',
						validator: limitCharacter
					}, ]
				},
				//添加标签
				addTagshow: false,
				addTagLoading: false,
				//编辑标
				editTagListshow: false,
				editTagListLoading: false,
				//添加标签组组组组组组组
				addTagListshow: false,
				addTagListLoading: false,

				currentId: 0, //当前的标签组Id

				editTableTag: {
					CRMTagDetailId: '',
					Name: '',
				},
				editTableTagRules: {
					Name: [{
						required: true,
						trigger: 'blur',
						validator: IsEmpty
					}, {
						required: true,
						trigger: 'change',
						validator: limitCharacter
					}, ]
				},
				editTableshow: false,
				editTableLoading: false,
				maxlength: 30,
				changeLeftTag: 0,
				mouseoverNum: -1,
				TagGroup: {},
			}
		},
		created() {
			this.gettagList()
			this.tosynchronous()
		},
		methods: {
			//去运营
			operating(e){
				this.$router.push({
					name:"configuration",
					params:e
				})
			},
			//去分配
			distribution(e){
				this.$router.push({
					name:"batchAddFriend",
					params:e
				})
			},
			mouseout() {
				this.mouseoverNum = -1
			},
			mouseover(e) {
				this.mouseoverNum = e
			},
			//已打标签人数跳转客户查询
			tocuseomerSearch(e) {
				this.$router.push({
					path: '/Customer/CustomerSearch',
					query: {
						customerTagId: e.CRMTagDetailId
					}
				})
			},
			//标签匹配规则跳转自动打标签
			toautoAddTag(e) {
				this.$router.push({
					path: '/Customer/autoAddTag',
					query: {
						CRMTagDetailId: e.CRMTagDetailId
					}
				})
			},
			//自动打标签
			jump() {
				this.$router.push({
					path: '/Customer/autoAddTag'
				})
			},
			limit30(e) {
				let len = 0
				let strnum = 0
				this.maxlength = 30 //每次初始化一下输入最大值  不然在输入值满了之后 删除一位就无法再输入
				for (let i = 0; i < e.length; i++) {
					var a = e.charAt(i);
					if (a.match(/[^\x00-\xff]/ig) != null) {
						//汉字占两个字符
						len += 2;
					} else {
						//其他占一个字符
						len += 1;
					}
					if (len < 30) {
						strnum += 1
					} else {
						this.maxlength = strnum
					}
				}
				// console.log(this.maxlength,len)
			},
			//删除表格的标签
			deltable(e) {
				let that = this
				this.$confirm(`是否确认删除标签【${e.Name}】?`, '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(() => {
					// console.log(e.CRMTagGroupId)
					let parents = this.leftGroupList.find((v) => {
						return v.CRMTagGroupId == e.CRMTagGroupId
					})
					// console.log(parents)
					if (parents.CRMTagDetailCount <= 1) {
						that.$alert('标签删除失败,标签组内至少保存1个标签', '提示', {
								confirmButtonText: '好的',
								type: 'warning'
							}).then(() => {})
							.catch(() => {})
					} else {
						this.totagdel(e)
					}
				}).catch(() => {})
			},
			async totagdel(e) {
				this.pageloading = true
				try {
					let Id = e.CRMTagDetailId
					let data = {
						CRMTagDetailId: Id
					}
					let result = await crmTagDetaildel(data)
					if (result.IsSuccess) {
						// console.log(e)
						this.leftGroupList.find((v) => {
							if (v.CRMTagGroupId == e.CRMTagGroupId) {
								v.CRMTagDetailCount -= 1
								return
							}
						})
						this.$message.success('操作成功')
					}
				} finally {
					this.pageloading = false
					this.currentPage = 1
					this.gettagList()
					// this.gettablelist(this.currentId?this.currentId:this.leftGroupList[0].CRMTagGroupId)
				}
			},
			//编辑表格中的标签  确认提交
			saveEditTableTag(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.totagedit()
					} else {
						return false;
					}
				});
			},
			async totagedit() {
				this.editTableLoading = true
				try {
					let data = this.editTableTag
					let result = await crmTagDetailedit(data)
					if (result.IsSuccess) {
						this.$message.success('操作成功')
					}
				} finally {
					this.editTableLoading = false
					this.editTableshow = false
					this.gettagList()
					// this.gettablelist(this.currentId)
				}
			},
			//编辑表格内的标签
			edittable(e) {
				this.editTableTag = JSON.parse(JSON.stringify(e))
				this.editTableshow = true
			},
			editTableClosed() {
				this.editTableTag = {
					CRMTagDetailId: '',
					Name: '',
				}
				this.$refs.editTableForm.resetFields()
			},
			tagInput() {
				// this.$forceUpdate()
			},
			//添加标签  确认提交
			saveAddTag(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.totagadd()
					} else {
						setTimeout(() => {
							var isError = document.getElementsByClassName("is-error")
							if (isError[0].querySelector('input')) {
								isError[0].querySelector('input').focus();
							}
						}, 1)
						return false;
					}
				});
			},
			async totagadd() {
				this.addTagLoading = true
				try {
					let data = {
						CRMTagGroupId: this.addruleForm.CRMTagGroupId,
						CRMTagDetails: this.addruleForm.CRMTagDetails.map((v) => {
							return v.Name
						})
					}
					// console.log(data)
					let result = await crmTagDetailadd(data)
					// console.log(result)
					if (result.IsSuccess) {
						this.$message.success('操作成功')
					}
				} finally {
					this.addTagLoading = false
					this.addTagshow = false
					this.gettagList()
				}
			},
			//添加标签  删除标签
			closeIconDelTag(e) {
				this.addruleForm.CRMTagDetails.splice(e, 1)
				this.$forceUpdate()
			},
			//添加标签内的添加标签
			pushInTag() {
				let data = {
					Name: ''
				}
				this.addruleForm.CRMTagDetails.push(data)
				this.$forceUpdate()
			},
			//添加标签
			tagAdd(e) {
				this.addruleForm = Object.assign(this.addruleForm, e);
				let CRMTagDetails = [{
					Name: '',
				}, ]
				this.addruleForm.CRMTagDetails = CRMTagDetails
				this.$forceUpdate()
				this.addTagshow = true

			},
			//编辑标签组
			tagListEdit(e) {
				this.editruleForm = JSON.parse(JSON.stringify(e))
				let data = [{
					Name: ''
				}]
				this.editruleForm.CRMTagDetails = data
				// console.log()
				this.editTagListshow = true
			},
			//编辑标签组 确认提交
			saveEditTagList(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.togroupedit()
					} else {
						return false;
					}
				});
			},
			async togroupedit() {
				this.editTagListLoading = true
				try {
					let data = this.editruleForm
					data.CRMTagDetails = data.CRMTagDetails.map((v) => {
						return v.Name
					})
					let result = await crmTagGroupedit(data)
					if (result.IsSuccess) {
						this.$message.success('操作成功')
					}
				} finally {
					this.editTagListLoading = false
					this.editTagListshow = false
					this.gettagList()
				}

			},
			//添加标签组 确定提交
			saveAddTagList(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.togroupadd()
					} else {
						setTimeout(() => {
							var isError = document.getElementsByClassName("is-error")
							if (isError[0].querySelector('input')) {
								isError[0].querySelector('input').focus();
							}
						}, 1)
						return false;
					}
				});
			},
			//添加标签组
			async togroupadd() {
				this.addTagListLoading = true
				try {
					let data = {
						Name: this.addListruleForm.Name,
						CRMTagDetails: this.addListruleForm.CRMTagDetails.map((v) => {
							return v.Name
						}),
					}
					let result = await crmTagGroupadd(data)
					if (result.IsSuccess) {
						// console.log(result)
						this.$message.success('操作成功')
					}
				} finally {
					this.addTagListLoading = false
					this.addTagListshow = false
					this.gettagList()
				}
			},
			//关闭添加标签组回调
			addTagListClosed() {
				this.addListruleForm = {
					Name: '',
					CRMTagDetails: [{
						Name: ''
					}, ],
				}
				this.$refs.addTagListForm.resetFields()
				this.$forceUpdate()
			},
			//关闭编辑标签
			editTagListClosed() {
				this.editruleForm = { //编辑标签组
					Name: '',
					CRMTagDetailCount: 0,
					CRMTagGroupId: 0,
				}
				this.$refs.editTagListForm.resetFields()
				this.$forceUpdate()
			},
			//关闭添加标签
			addTagClosed() {
				this.addruleForm = { //添加小标签
					CRMTagGroupId: 0,
					Name: '',
					CRMTagDetailCount: 0,
					CRMTagDetails: [{
						Name: '',
					}, ]
				}
				this.$refs.addTagForm.resetFields()
				this.$forceUpdate()
			},
			//添加标签组 删除标签
			closeIconDel(e) {
				this.addListruleForm.CRMTagDetails.splice(e, 1)
			},
			//添加标签组 添加标签
			pushTag() {
				let data = {
					Name: ''
				}
				this.addListruleForm.CRMTagDetails.push(data)
				this.$forceUpdate()
			},
			//删除标签组
			tagDetele(e) {
				this.$confirm(`是否确认删除标签组【${e.Name}】?删除后,组内的标签会同步删除`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.togroupdel(e)
				}).catch(() => {})
			},
			//删除标签组
			async togroupdel(e) {
				this.pageloading = true
				try {
					let Id = await e
					let data = {
						CRMTagGroupId: Id.CRMTagGroupId
					}
					let result = await crmTagGroupdel(data)
					if (result.IsSuccess) {
						this.$message.success('操作成功')
					}
				} finally {
					this.pageloading = false
					this.gettagList()
				}
			},
			//查询
			query() {
				this.changeLeftTag = 0
				this.currentPage = 1
				this.gettagList()
				this.gettablelist()
			},
			//添加标签组组组组组组组组
			tagListAdd() {
				this.addTagListshow = true
			},
			//导出
			exportList() {
				//   /pc/tagdetail/export
				let url = this.exportUrl + "/pc/tagdetail/export?Keywords=" + this.keywords
				window.open(url)
			},
			//点击更新相应的列表
			queryList(e, i) {
				if (e) {
					this.changeLeftTag = i
					this.currentId = e.CRMTagGroupId
					this.gettablelist(e.CRMTagGroupId)
				}
			},
			handleSizeChange(e) {
				this.currentPage = 1
				this.sizepage = e
				this.gettablelist(this.currentId)
			},
			handleCurrentChange(e) {
				this.currentPage = e
				this.gettablelist(this.currentId)
			},
			//同步
			async tosynchronous() {
				let that = this
				that.synchronous = {
					loading: true,
					tip: '同步中...'
				}
				setTimeout(function() {
					that.synchronous = {
						loading: false,
						tip: '同步企业微信'
					}
				}, 2000)

			},
			//获取标签组数据
			async gettagList() {
				this.leftLoading = true
				try {
					let data = {
						Keywords: this.keywords,
						Skip: 0,
						Take: 10000,
					}
					let result = await crmTagGrouplist(data)
					if (result.IsSuccess) {
						// console.log(this.keywords,result.Result.Results)
						this.leftGroupList = result.Result.Results
						if (result.Result.Results.length > 0) {
							this.gettablelist(this.leftGroupList[this.changeLeftTag].CRMTagGroupId)
						} else {
							this.gettablelist()
						}
						// this.gettablelist()
					}
				} finally {
					this.leftLoading = false
				}
			},
			//表格数据
			async gettablelist(e) {
				let Id = await e
				// console.log(Id)
				if (!Id) {
					Id = 0
				}
				// console.log(Id)
				this.tableloading = true
				let data = {
					Keywords: this.keywords,
					CRMTagGroupId: Id,
					Skip: (this.currentPage - 1) * this.sizepage,
					Take: this.sizepage,
				}
				let result = await crmTagDaillist(data)
				if (result.IsSuccess) {
					this.TagGroup = this.leftGroupList[this.changeLeftTag] || {};
					// console.log(this.keywords,result.Result.Results)
					this.tablelist = result.Result.Results
					this.total = result.Result.Total
					if (this.keywords) {
						result.Result.Results.map((v) => {
							// console.log(v.CRMTagGroupId)
							if (v.CRMTagGroupId) {
								let data = {
									CRMTagGroupId: v.CRMTagGroupId,
									Name: v.TagGroupName
								}
								this.leftGroupList.push(data)
							}
						})
						//数组对象去重
						let obj = {}
						let peon = this.leftGroupList.reduce((cur, next) => {
							obj[next.CRMTagGroupId] ? "" : obj[next.CRMTagGroupId] = true && cur.push(next);
							return cur;
						}, []) //设置cur默认类型为数组，并且初始值为空的数组 
						this.leftGroupList = peon
					}
				}
				this.tableloading = false
			},
		}
	};
</script>

<style lang='less'>
	.theFlex {
		display: flex;
		flex-direction: row;
		justify-content: space-between
	}

	.flexRow {
		display: flex;
		flex-direction: row;
	}

	.fontHidden1 {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.fontHidden {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		overflow: hidden;
	}

	.synchronousbtn {
		border-color: #0085DD;
		color: #0085DD;
		background: #ffffff
	}

	.synchronousBGC {
		background: #7FB0D0;
		box-shadow: 0px 0px 3px 2px white inset;
		color: white;
	}

	.tagNameBtn-Box {
		display: none
	}

	/* .tagNameBgc */
	.tagNameBgc {
		background: rgba(64, 158, 255, 0.07);
	}

	.tagNameBgc:hover .tagNameBtn-Box {
		display: block
	}

	.chooseTag {
		color: #409eff;
	}

	.search-box {
		display: flex;
		flex-wrap: wrap;
		flex: 1 1 auto;
	}

	.search-right {
		flex: 0 0 auto;
		margin-top: 10px;
	}

	.tag-item {
		display: flex;
		overflow: hidden;
		height: 40px;
		line-height: 40px;
		font-size: 14px;

		.tag-item-left {
			flex: 1 1 auto;
			display: flex;
			margin-right: 20px;

			.name {
				max-width: 200px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.count {
				color: #666;
				margin-left: 5px;
			}
		}

		.tag-item-right {
			flex: 0 0 auto;
		}
	}
</style>
